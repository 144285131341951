<!--
 * @Author: Do not edit
 * @Date: 2021-04-07 15:49:33
 * @LastEditors: zhulin
 * @LastEditTime: 2021-08-10 16:54:29
 * @Description: Do not edit
 * @FilePath: \enfry-website\src\views\Details.vue
-->
<template>
  <div class="details">
    <div class="banner">
      <div class="txt-inner wow bounceInLeft">
        <p>无代码化的数据</p>
        <p>全生命周期管理</p>
        <a class="btn" href="/Contact">预约演示</a>
      </div>
      <img src="@/assets/images/banner-yanshi-img.png" alt="" class="imgs  wow bounceInRight"/> 
    </div>
    <casePage></casePage>
    <div class="cent" id="contact">
      <!-- <div class="inner-title wow fadeInUp">欢迎咨询</div> -->
      <contactUs></contactUs>
    </div>
  </div>
</template>
<script>
// import axios from "axios";
import { WOW } from "wowjs";
import contactUs from "./../components/contactUs.vue";
import casePage from "../components/casePage"

export default {
  name: "Jion",
  components: {
    contactUs,
    casePage
  },
  data() {
    return {
      form: {
        name: "",
        companyName: "",
        mobileNo: "",
        content: " "
      },
      formRules: {
        name: [
          {
            required: true,
            message: "请输入您的姓名",
            trigger: "blur"
          }
        ],
        companyName: [
          {
            required: true,
            message: "请输入您的律所名称",
            trigger: "blur"
          }
        ],
        mobileNo: [
          {
            required: true,
            message: "请输入您的联系号码",
            trigger: "blur"
          },
          {
            pattern: /^1\d{10}$/,
            message: "手机号格式不正确",
            trigger: "blur"
          }
        ]
      }
    };
  },
  mounted() {
    this.$nextTick(() => {
      const wow = new WOW({
        live: false
      });
      wow.init();
      let conact = window.location.hash;
      if (conact) {
        this.goAnchor(window.location.hash);
      }
    });
  },
  methods: {
    goAnchor(selector) {
      let anchor = this.$el.querySelector(selector);
      document.documentElement.scrollTop = anchor.offsetTop;
    }
  }
};
</script>
<style lang="scss" scoped>
.banner {
  height: 638px;
  position: relative;
  &::before{
    content:"";
    background: url("~@/assets/images/banner-yanshi-bg1.png") right top no-repeat;
    position: absolute;
    display: block;
    width:1208px;
    height:342px;
    top:0;
    right: 0;
  }
  &::after{
    content:"";
    background: url("~@/assets/images/banner-yanshi-bg2.png") left bottom no-repeat;
    position: absolute;
    display: block;
    width:531px;
    height:214px;
    bottom: 0;
    left:0;
  }
  .imgs{
    position: absolute;
    left: 50%;
    top:55px;
    margin-left:-170px;
  }
  .txt-inner {
    text-align: left;
    position: absolute;
    color:#0183de;
    right: 50%;
    top:210px;
    margin-right: 170px;
    width:400px;
    z-index: 10;
    p {
      font-size: 48px;
      margin:10px 0;
    }
    .btn{
      background: #0183de;
      color: #fff;
      width:230px;
      display: block;
      height: 50px;
      line-height: 50px;
      text-align: center;
      font-size: 18px;
      border-radius: 5px;
      margin-top:60px;
      text-decoration: none;
      cursor: pointer;
    }
  }
}
.inner-title {
  font-size: 30px;
  color: #333333;
  line-height: 50px;
  padding-top: 50px;
  font-weight: normal;
}
.cent {
  padding-bottom: 100px;
  color: #333;
  padding-top:60px;
  .info {
    color: #91a1b7;
    font-size: 16px;
    text-align: center;
  }
  .inner-cent {
    width: 600px;
    margin: 0 auto;
    padding-top: 30px;
    text-align: left;
  }
  /deep/ .el-form--label-top .el-form-item__label {
    padding-bottom: 0;
    font-size: 16px;
  }
  /deep/ textarea {
    height: 95px;
  }
  .button {
    background: #1593ff;
    color: #fff;
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 18px;
    border-radius: 5px;
  }
}
@media (max-width: 750px) {
  .inner-title {
    font-size: 22px;
    line-height: 24px;
    padding-top: 30px;
    font-weight: bold;
  }
  .banner {
    height: 310px;
    overflow: hidden;
    &::before{
      width: 230px;
      height: 100px;
      background-size:100%;
    }
    &::after{
      width: 150px;
      height: 100px;
      background-size:100%;
    }
    .imgs{
      margin-left: -116px;
      width: 60%;
      top: 30px;
    }
    .txt-inner {
      padding-top: 192px;
      top:12px;
      width:100%;
      margin:0 auto;
      left:0;
      text-align: center;
      h3 {
        font-size: 24px;
        margin: 0;
      }
      p {
        font-size: 20px;
        margin-top: 12px;
        line-height: 30px;
        margin: 0;
        display:inline;
        margin-right:5px;
      }
      .btn{
        width: 130px;
        height: 34px;
        line-height: 34px;
        font-size: 16px;
        margin:14px auto
      }
    }
  }
  .cent{
    padding-top:0;
  }
  .cent .inner-cent {
    width: 90%;
  }
}
</style>
